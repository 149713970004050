.menu-btn {
  position: relative;
  overflow: hidden;
}
.menu-btn::after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: -20%;
  width: 7%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  transform: skew(40deg, 0);
  transition: all 0.4s ease-in-out;
}
.menu-btn:hover::after {
  left: 120%;
}
