/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .skillicon {
    @apply rounded-xl cursor-pointer  m-0.5 sm:m-1;
  }

  .skilliconchild {
    @apply relative rounded-xl overflow-hidden filter brightness-75 w-full h-full bg-gradient-to-b from-black hover:from-gray-900 to-gray-700 hover:to-gray-700;
  }

  .skilliconchild:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 0px);
    height: 35%;
    background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.2)
    );
  }

  .skilliconchild:hover {
    @apply filter brightness-100;
  }

  .skilliconchild-active {
    @apply relative rounded-xl overflow-hidden filter brightness-100 w-full h-full bg-gradient-to-b from-gray-900 to-gray-700;
  }

  .skilliconchild-active:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100% - 0px);
    height: 35%;
    background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.2)
    );
  }

  .card {
    @apply w-full backdrop-filter backdrop-blur-3xl text-gray-300 bg-opacity-70 hover:bg-gray-700 hover:bg-opacity-60 hover:text-gray-200 cursor-pointer rounded-lg h-36 bg-gray-800 flex overflow-hidden;
  }

  .card:hover .card-image {
    @apply transition duration-500 ease-in-out transform -translate-y-1 scale-110 brightness-100;
  }

  .card-image-box {
    @apply w-2/5 sm:w-1/3 h-full bg-cover bg-center overflow-hidden;
  }

  .card-image {
    @apply transition duration-500 w-full h-full bg-gray-300 filter brightness-90;
  }
}
